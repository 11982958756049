import { Typography } from "@mui/material";
import { FaqQuestionBorder, FaqQuestionContainer } from "./styled";
import PlusIcon from "../../assets/svg/plus.svg";
import SubtractIcon from "../../assets/svg/subtract.svg";
import { FAQProps } from "./common";
import { useState } from "react";
import { FlexCenteredBetween } from "../../styles/styled-component";

export const FaqItem = ({ item }: { item: FAQProps }) => {
  const [open, setOpen] = useState(false);

  const toggleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <FaqQuestionBorder onClick={toggleClick}>
      <FaqQuestionContainer>
        <FlexCenteredBetween style={{ gap: "10px" }}>
          <Typography align="left">{item.question}</Typography>
          {open ? (
            <img src={SubtractIcon} alt="Subtract Icon" />
          ) : (
            <img src={PlusIcon} alt="Plus Icon" />
          )}
        </FlexCenteredBetween>
        {open && (
          <Typography
            fontWeight={400}
            align="left"
            color="#FFFFFF99"
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
        )}
      </FaqQuestionContainer>
    </FaqQuestionBorder>
  );
};

import { Typography } from "@mui/material";
import {
  FlexCenteredBothAxises,
  FlexCenteredHorizontally,
} from "../../../styles/styled-component";
import {
  summitAnalyticsData,
  summitAnalyticsDataImg,
  SummitAnalyticsDataItem,
  summitAnalyticsDataText,
  summitAnalyticsText,
} from "../common";
import {
  LightEffect,
  StyledGrid,
  SummitAnalyticMobileDataContainer,
  SummitAnalyticMobileImgsContainer,
  SummitAnalyticsContainer,
  SummitAnalyticsImgContainer,
  SummitAnalyticsWrapper,
} from "../styled";
import logo from "../../../assets/svg/logo.svg";
import { useIsMobile } from "../../../utils/use-is-mobile";

export const SummitAnalytics = () => {
  const isMobile = useIsMobile();

  const renderAnalytics = (
    data: SummitAnalyticsDataItem[],
    columns: string,
    index: number
  ) => (
    <StyledGrid columns={columns} key={index}>
      {data.map((item, index) => (
        <FlexCenteredHorizontally key={index}>
          <SummitAnalyticsImg item={item} />
        </FlexCenteredHorizontally>
      ))}
    </StyledGrid>
  );

  const renderDesktopAnalytics = () => {
    const desktopData = [
      { data: summitAnalyticsData.slice(0, 3), columns: "1fr 2.3fr 2fr" },
      { data: summitAnalyticsData.slice(3, 6), columns: "1.5fr 1.9fr 1fr" },
      { data: summitAnalyticsData.slice(6, 9), columns: "2.4fr 1fr 1fr" },
    ];

    return desktopData.map((line, index) =>
      renderAnalytics(line.data, line.columns, index)
    );
  };

  return (
    <FlexCenteredBothAxises
      style={{
        flexDirection: "column",
        marginTop: isMobile ? "88px" : "216px",
      }}
    >
      {!isMobile && renderDesktopAnalytics()}

      {isMobile && (
        <SummitAnalyticMobileImgsContainer>
          {summitAnalyticsDataImg.map((img, index) => (
            <img
              key={index}
              src={img}
              alt=""
              style={{
                width: "80%",
                height: "100%",
                minHeight: "120px",
                objectFit: "cover",
                maxHeight: isMobile ? "150px" : "100%",
              }}
            />
          ))}
        </SummitAnalyticMobileImgsContainer>
      )}

      {isMobile && (
        <SummitAnalyticMobileDataContainer>
          {summitAnalyticsDataText.map((item, index) => (
            <FlexCenteredHorizontally
              style={{ flexDirection: "column" }}
              key={index}
            >
              <Typography fontSize={35} fontWeight={500} align="center">
                {item.amount}
              </Typography>
              <Typography fontSize={14} fontWeight={500} align="center">
                {item.text}
              </Typography>
            </FlexCenteredHorizontally>
          ))}
        </SummitAnalyticMobileDataContainer>
      )}

      <SummitAnalyticsContainer>
        <Typography
          variant={isMobile ? "body1" : "h5"}
          fontWeight={300}
          dangerouslySetInnerHTML={{ __html: summitAnalyticsText }}
          align="justify"
        />
        <SummitAnalyticsWrapper>
          <Typography variant={isMobile ? "body1" : "h5"}>
            Where institutions meet innovation.
          </Typography>
          <img src={logo} alt="Gamma Prime Logo" height={isMobile ? 27 : 43} />
        </SummitAnalyticsWrapper>
      </SummitAnalyticsContainer>
    </FlexCenteredBothAxises>
  );
};

function SummitAnalyticsImg({ item }: { item: SummitAnalyticsDataItem }) {
  const isMobile = useIsMobile();
  return item.type === "img" ? (
    <img
      src={item.url}
      alt={item.alt}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        maxHeight: isMobile ? "150px" : "100%",
      }}
    />
  ) : (
    <SummitAnalyticsImgContainer>
      <LightEffect top={item.top} left={item.left} />
      <Typography fontSize={isMobile ? 36 : 60} fontWeight={500}>
        {item.amount}
      </Typography>
      <Typography fontSize={isMobile ? 14 : 25} fontWeight={500}>
        {item.text}
      </Typography>
    </SummitAnalyticsImgContainer>
  );
}

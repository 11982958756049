import styled from "@emotion/styled";
import { Button, Stack } from "@mui/material";
import { FlexCenteredBothAxises } from "../../styles/styled-component";

export const FaqQuestionBorder = styled(Button)({
  width: "100% ",
  borderRadius: "8px",
  padding: "1px",
  background:
    "linear-gradient(90deg, rgba(255, 255, 255, 0.14) 0%, rgba(8, 17, 22, 0.14) 100%)",
  boxShadow: "0 0 0 1px transparent inset",
  color: "white",
  textTransform: "inherit",
});

export const FaqQuestionContainer = styled(Stack)({
  width: "100%",
  padding: "16px 20px",
  borderRadius: "8px",
  background: "transparent",
  backgroundColor: "#081116",
  gap: "10px",
  backdropFilter: "blur(10px)",
});

export const FaqItemWrapper = styled(FlexCenteredBothAxises)({
  flexDirection: "column",
  marginTop: "24px",
  maxWidth: "892px",
  width: "100%",
  gap: "12px",
});

import { Box, Typography } from "@mui/material";
import { FlexCenteredBothAxises } from "../../../styles/styled-component";
import SummitLocationImg from "../../../assets/png/summit-location.png";
import { ButtonStyled } from "../../home/styled";
import { useIsMobile } from "../../../utils/use-is-mobile";
import { Badge } from "../../../components/badge";
import {
  FrameHorizontal,
  FrameVertical,
  SummitLocationImgContainer,
} from "../styled";
import { useRef, useState } from "react";

export const SummitLocation = () => {
  const isMobile = useIsMobile();
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const imgRef = useRef<HTMLImageElement>(null);

  const handleImageLoad = () => {
    if (imgRef.current) {
      setImgDimensions({
        width: imgRef.current.offsetWidth,
        height: imgRef.current.offsetHeight,
      });
    }
  };
  return (
    <FlexCenteredBothAxises
      style={{
        flexDirection: "column",
        marginBottom: "87px",
        marginLeft: "19px",
        marginRight: "19px",
      }}
    >
      <Badge text="Location" type="gradient" size="small" />
      <Typography
        fontSize={isMobile ? 32 : 64}
        fontWeight={500}
        lineHeight={isMobile ? "30px" : "60px"}
        align="center"
        marginY="15px"
      >
        Highlands Ranch <br /> Mansion & Historic Park
      </Typography>
      <Typography color="#FFFFFF" sx={{ opacity: "60%" }} align="center">
        9950 E Gateway Dr. Highlands Ranch, CO 80126
      </Typography>

      <Box position="relative" marginTop="41px">
        <Box position="relative" maxWidth="991px">
          <FrameVertical
            top={isMobile ? "-10%" : "-20%"}
            left="0px"
            dimensions={imgDimensions}
          />
          <FrameHorizontal
            top="0px"
            left={isMobile ? "-6%" : "-11%"}
            dimensions={imgDimensions}
          />
          <img
            src={SummitLocationImg}
            alt="Summit Location"
            width="100%"
            style={{
              objectFit: "cover",
            }}
            ref={imgRef}
            onLoad={handleImageLoad}
          />
          <FrameVertical
            top={isMobile ? "-10%" : "-20%"}
            left="100%"
            dimensions={imgDimensions}
          />
          <FrameHorizontal
            top="100%"
            left={isMobile ? "-6%" : "-11%"}
            dimensions={imgDimensions}
          />
        </Box>

        <SummitLocationImgContainer>
          <Typography
            maxWidth={isMobile ? "100%" : "348px"}
            alignSelf={isMobile ? "center" : "stretch"}
            align={isMobile ? "center" : "left"}
          >
            Nestled in the heart of Highlands Ranch, this stunning mansion spans
            over 27,000 square feet and boasts a blend of architectural styles,
            including Western, Tudor, and Gothic influences, dating back to its
            origins in the late 19th century.
          </Typography>
          <ButtonStyled
            variant="contained"
            href="https://www.google.com.ua/maps/place/Highlands+Ranch+Mansion/@39.5360298,-104.9725178,18.31z/data=!4m15!1m8!3m7!1s0x876c83063299b5a5:0x941269e27cfcfb46!2s9950+E+Gateway+Dr,+Highlands+Ranch,+CO+80126,+%D0%A1%D0%A8%D0%90!3b1!8m2!3d39.5359739!4d-104.9715728!16s%2Fg%2F11gbkyw2_3!3m5!1s0x876c8308546866c7:0x747b6e822fdc45da!8m2!3d39.5360422!4d-104.9712652!16zL20vMGJkanh4?hl=ru&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
            width="185px"
          >
            Find on Google Maps
          </ButtonStyled>
        </SummitLocationImgContainer>
      </Box>
    </FlexCenteredBothAxises>
  );
};

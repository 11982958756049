import { Badge } from "../badge";
import { Stack, Typography } from "@mui/material";
import { useIsMobile } from "../../utils/use-is-mobile";
import { FC, memo } from "react";
import { faqData } from "./common";
import { FaqItem } from "./faq-item";
import { FaqItemWrapper } from "./styled";

export const FAQ: FC = memo(() => {
  const isMobile = useIsMobile();
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      margin="0 19px"
      marginTop={isMobile ? "70px" : "150px"}
      position="relative"
      zIndex={111}
    >
      <Badge text="FAQ" type="gradient" size="small" />
      <Typography
        fontSize={isMobile ? 32 : 48}
        fontWeight={500}
        marginTop={isMobile ? "7px" : "14px"}
        align="center"
      >
        You might be wondering:
      </Typography>
      <FaqItemWrapper>
        {faqData.map((item, index) => (
          <FaqItem item={item} key={index} />
        ))}
      </FaqItemWrapper>
    </Stack>
  );
});

FAQ.displayName = "FAQ";

export type FAQProps = {
  question: string;
  answer: string;
};

export const faqData: FAQProps[] = [
  {
    question: "What is the Tokenized Capital Summit?",
    answer:
      "The Tokenized Capital Summit is the leading institutional event on Real-World Assets (RWA) and Tokenization. Held on February 25th in Colorado, the summit will bring together thousands of participants, including top founders, builders, institutional leaders, representatives of largest venture capitals, funds & family offices. A premier event focused on exploring the future of tokenized assets, blockchain innovations, and capital markets, bringing together industry leaders, investors, and innovators.",
  },
  {
    question: "Where and when is the event taking place?",
    answer:
      "The summit will be held in Denver, Colorado, on February 25th, 2025. To make sure you are seeing the exact address of the event, refer to the conference’s page on luma (go to “Buy Tickets”).",
  },
  {
    question: "Who is hosting the event? ",
    answer:
      "The event is hosted by Gamma Prime, the leading platform for tokenizing traditional funds, unlocking global liquidity and powering a next-gen financial ecosystem.",
  },
  {
    question: "What is the theme of the 2025 summit?",
    answer:
      "The theme revolves around Tokenizing Traditional Capital, Real World Assets, and unlocking new yield opportunities for traditional funds meanwhile onboarding new liquidity onto web3.",
  },
  {
    question: "Who can attend the event?",
    answer:
      "The summit is open to everyone with a ticket. UHNWI, top fund managers, family offices & institutional investors have prioritized access.",
  },
  {
    question: "How do I register for the event?",
    answer:
      "Registration can be completed online: navigate to the “Buy Tickets” button on top of the website.",
  },
  {
    question: "Who are the speakers?",
    answer: `The event brings together an exceptional lineup of speakers, representing some of the most influential names in the blockchain, DeFi, and finance sectors.
      <br/><br/>
      From the investment side, leading funds include Dragonfly Capital, Figment Fund, ODIG Ventures, ARCHE Capital, TB Ventures, MV Global, Asymmetry Capital, M55 Capital, Volt Capital, Acquire.Fi, Consensys, Re7 Capital, and Uphonest Capital. These funds showcase diverse expertise across venture capital, asset management, and blockchain-focused investments, providing a wealth of insights into emerging trends and opportunities in the space.
      <br/><br/>
      The event also features representatives from prominent blockchain networks, including zkSync, Injective, Polyhedra, Akash Network, Fuse Network, Monad, Avalanche, Polygon Labs, Sonic Labs (formerly Fantom), Manta Network, Linea, Aurora, and Calimero Network. These chains are at the forefront of scalability, interoperability, privacy, and decentralized infrastructure, driving the next wave of innovation in web3.
      <br/><br/>
      Decentralized applications (dApps) will also play a key role, with speakers from Wormhole, Aethir, Cygnus Finance, Meta Pool, Beefy Finance, and Bancor. These projects bring insights into cross-chain interoperability, decentralized cloud infrastructure, yield optimization, staking solutions, and automated market-making protocols, showcasing the practical applications of blockchain technology. Adding further depth, Cointelegraph will provide media insights, offering perspectives on the evolving narrative around blockchain and decentralized finance. Additionally, Blockfills bridges the gap between traditional finance and web3, bringing expertise in integrating traditional financial systems with blockchain technology. Together, this diverse group of speakers promises a comprehensive exploration of the blockchain ecosystem, fostering meaningful discussions and connections.
      <br/><br/>
      New speakers are being added daily.`,
  },
  {
    question: "Can I view the agenda before the event?",
    answer:
      "Yes, a detailed agenda will be available on the event website by early February 2025.",
  },
  {
    question: "Will there be networking opportunities?",
    answer:
      "Absolutely! Networking sessions are integrated throughout the day, including dedicated breakout rooms and an evening cocktail reception.",
  },
  {
    question: "Is parking available at the venue?",
    answer:
      "Yes, parking details and nearby public transport options will be provided closer to the event date.",
  },
  {
    question: "How can my company sponsor the event?",
    answer: `Sponsorship opportunities are available, and you can request a sponsorship prospectus by contacting <a href="mailto:marketing@gammaprime.fi">marketing@gammaprime.fi</a> or filling the <a href="https://docs.google.com/forms/d/e/1FAIpQLSeaA3EXKPAWj5YpZsuzngBKBURzaRN-pEmkafTDBmLkfLwpwg/viewform?usp=send_form" target="_blank" rel="noopener noreferrer">sponsorship form</a>.`,
  },
  {
    question: "Will there be opportunities for media coverage?",
    answer: `Accredited media representatives can register for press passes via <a href="mailto:marketing@gammaprime.fi">marketing@gammaprime.fi</a> or messaging <a href="http://t.me/i_gmp" target="_blank" rel="noopener noreferrer">@i_gmp</a> on Telegram.`,
  },
  {
    question: "Will session recordings be available?",
    answer:
      "Yes, recordings of key sessions will be available after the event.",
  },
];

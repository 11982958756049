import { Typography } from "@mui/material";
import {
  SummitPartnersLogosContainer,
  SummitPartnersLogosWrapper,
} from "../styled";
import { summitPartnersLogos } from "../common";
import { FlexCenteredBothAxises } from "../../../styles/styled-component";
import { useIsMobile } from "../../../utils/use-is-mobile";

export const SummitPartners = () => {
  const isMobile = useIsMobile();
  return (
    <FlexCenteredBothAxises
      style={{
        flexDirection: "column",
        marginBottom: "71px",
        marginLeft: "19px",
        marginRight: "19px",
      }}
    >
      <Typography
        fontWeight={500}
        fontSize={isMobile ? 16 : 24}
        align="center"
        marginBottom={isMobile ? "32px" : "53px"}
      >
        Trusted by leading institutional partners
      </Typography>

      <SummitPartnersLogosWrapper>
        {summitPartnersLogos.map((logo, index) => (
          <SummitPartnersLogosContainer key={index}>
            <img
              src={logo.url}
              alt={logo.alt}
              width={isMobile ? "80%" : "100%"}
              height="100%"
            />
          </SummitPartnersLogosContainer>
        ))}
      </SummitPartnersLogosWrapper>
    </FlexCenteredBothAxises>
  );
};

import { Box, Typography } from "@mui/material";
import React, { FC, Suspense, memo, useCallback } from "react";
import { ButtonStyled, ContentWrap, Figure, LineWrap, TitleStyled, Wrapper } from "./styled";
import { FlexCenteredVertically } from "../../styles/styled-component";
import { Whitepapper } from "./components/whitepapper";
import { Form } from "../../components/form";
import { Line } from "../../assets/tsx/line";
import { useIsMobile } from "../../utils/use-is-mobile";
import { DecadesOfTradfi } from "./components/decades-of-tradfi";
import { Team } from "../../components/team";
import Join from "./components/join";
import { useGetStrategiesQuery } from "../../services/gama-data";
import {
	GradientLineHorizontal,
	GradientWrap,
	GradientWrapReverse,
} from "../../components/gradient-line";
const RealYield = React.lazy(() => import("./components/real-yield"));
const WorldSection = React.lazy(() => import("./components/world-section"));

export const HomePage: FC = memo(() => {
	const isMobile = useIsMobile();
	const { data } = useGetStrategiesQuery(null);
	const deltaStrategy = data?.strategies.find((el) => el.slug === "delta");
	const growthStrategy = data?.strategies.find((el) => el.slug === "growth");

	const apyData = [
		{
			label: "DELTA NEUTRAL:",
			value: deltaStrategy?.apy.weekly ?? 10.95,
			color: "#D384F8",
			type: "purple",
		},
		{
			label: "GROWTH STRATEGY:",
			value: growthStrategy?.apy.weekly ?? 18.43,
			color: "#06FFE1",
			type: "green",
		},
	] as const;

	const handleScroll = useCallback((event: any, id: string) => {
		event.preventDefault();
		const section = document.getElementById(id);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<FlexCenteredVertically flexDirection="column" position="relative">
			<Figure
				sx={{
					width: 660,
					height: 996,
					transform: "rotate(160.84deg)",
					right: "-40%",
					top: "1%",
				}}
			/>

			<Figure
				sx={{
					width: 660,
					height: 996,
					transform: "rotate(-160.84deg)",
					left: "-42%",
					top: "9%",
				}}
			/>
			<ContentWrap>
				<LineWrap sx={{ left: "0%", top: "15%" }} delay={0.4}>
					<Line height={161} />
				</LineWrap>

				<LineWrap sx={{ left: "11%", top: "73%" }}>
					<Line height={161} />
				</LineWrap>

				<LineWrap sx={{ right: "0%", top: "25%" }} delay={0.2}>
					<Line height={100} />
				</LineWrap>

				<LineWrap sx={{ right: "11%", top: "65%" }} delay={0.6}>
					<Line height={161} />
				</LineWrap>
				<TitleStyled variant="h1">
					Bringing the World of <br /> Hedge Funds to Web3
				</TitleStyled>
				<FlexCenteredVertically marginTop={isMobile ? "16px" : "32px"} gap="35px">
					{apyData.map((el) => (
						<Box
							display="flex"
							flexDirection="column"
							gap="12px"
							key={el.label}
							alignItems="center"
						>
							<GradientWrap>
								<GradientLineHorizontal type={el.type} width={60} />
							</GradientWrap>
							<Box>
								<Typography fontSize={12} fontWeight="bold" textAlign="center">
									{el.label}
								</Typography>
								<Typography
									fontSize={12}
									fontWeight="bold"
									color={el.color}
									textAlign="center"
								>
									{el.value}% APY
								</Typography>
							</Box>
							<GradientWrapReverse>
								<GradientLineHorizontal type={el.type} width={60} rotate="180deg" />
							</GradientWrapReverse>
						</Box>
					))}
				</FlexCenteredVertically>

				<Typography
					variant={isMobile ? "body2" : "body1"}
					fontWeight={400}
					letterSpacing="-0.48px"
					sx={{ opacity: 0.6 }}
					mt={isMobile ? "16px" : "32px"}
					maxWidth="532px"
					textAlign="center"
					padding={isMobile ? "0 16px" : "0"}
				>
					Democratizing access to hedgefund yields through tokenization with institutional
					grade infrastructure and regulatory compliance.
				</Typography>

				<FlexCenteredVertically
					marginTop="24px"
					gap="16px"
					flexDirection={isMobile ? "column" : "row"}
					justifyContent="center"
					width="100%"
					padding="0 16px"
				>
					<ButtonStyled
						onClick={() =>
							window.open("https://app.gammaprime.fi/challenge/landing", "_blank")
						}
						variant="contained"
						width={isMobile ? "100%" : 210}
					>
						Enter Challenge
					</ButtonStyled>

					<ButtonStyled
						variant="outlined"
						width={isMobile ? "100%" : 210}
						onClick={() => window.open("https://app.gammaprime.fi/invest", "_blank")}
					>
						Enter Testnet
					</ButtonStyled>
				</FlexCenteredVertically>
			</ContentWrap>

			<Suspense fallback={<div />}>
				<WorldSection />
			</Suspense>

			<Suspense fallback={<div />}>
				<RealYield />
			</Suspense>

			<DecadesOfTradfi />

			<Join />

			<Whitepapper />

			<Wrapper>
				<Team />
			</Wrapper>

			<Form title={"Secure Your Position at the Forefront of Finance"} badge="Join Beta" />
		</FlexCenteredVertically>
	);
});

HomePage.displayName = "HomePage";

import styled from "@emotion/styled";
import { Box, Grid, Stack } from "@mui/material";
import {
  FlexCenteredBothAxises,
  FlexCenteredHorizontally,
  FlexCenteredVertically,
} from "../../styles/styled-component";
import { forMobile } from "../../styles/adaptivity";

export const StyledGrid = styled(Box)(({ columns }: { columns: string }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: columns,
  backgroundColor: "#090E1F",
}));

export const SummitPartnersLogosWrapper = styled(Grid)({
  maxWidth: "1020px",
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: "35px",

  [forMobile]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "7px",
  },
});

export const SummitPartnersLogosContainer = styled(FlexCenteredBothAxises)({
  border: "1px solid #FFFFFF0F",
  borderRadius: "12px",
  width: "165px",
  padding: "10px",
  height: "98px",
  boxShadow:
    "0px 0px 16px -7px #9AAAFF0D, 0px 2px 40px 10px #9AAAFF0D, 0px 1px 1px 1px #FFFFFF1A inset",

  [forMobile]: {
    padding: "0px",
    width: "113px",
    height: "63px",
    borderRadius: "8px",
  },
});

export const SummitScheduleWrapper = styled(FlexCenteredBothAxises)({
  borderRadius: "25px",
  border: "1px solid white",
  width: "100%",
  height: "86px",
  flexShrink: 0,
  maxWidth: "526px",

  [forMobile]: {
    height: "45px",
    borderRadius: "13px",
  },
});

export const LeftLight = styled(Box)({
  position: "absolute",
  width: "662px",
  height: "598px",
  borderRadius: "662px",
  opacity: 0.5,
  background: "#39CA88",
  filter: "blur(241.39999389648438px)",
  left: "-50%",
  top: "-70%",
});

export const RightLight = styled(Box)({
  position: "absolute",
  width: "662px",
  height: "598px",
  borderRadius: "662px",
  opacity: 0.5,
  background: "#39CA88",
  filter: "blur(241.39999389648438px)",
  top: "-70%",
  right: "-50%",
});

export const HideComponent = styled(Box)({
  position: "absolute",
  width: "2358px",
  height: "832px",
  backgroundColor: "#081117",
  filter: "blur(175px)",
  top: "15%",
  left: "-20%",
  zIndex: 1,

  [forMobile]: {
    left: "-80%",
    top: "-20%",
  },
});

export const LightEffect = styled(Box)<{
  top: string;
  left: string;
}>(({ top, left }) => ({
  position: "absolute",
  width: "375px",
  height: "278px",
  borderRadius: "50%",
  opacity: 0.5,
  background: "#39CA88",
  filter: "blur(100px)",
  top: top,
  left: left,
}));

export const SummitLocationImgContainer = styled(FlexCenteredVertically)({
  position: "absolute",
  flexDirection: "row",
  bottom: "40px",
  left: "35px",
  justifyContent: "space-between",
  alignItems: "flex-end",
  width: "900px",
  marginTop: "0px",

  [forMobile]: {
    position: "relative",
    flexDirection: "column",
    bottom: "0px",
    left: "0px",
    marginTop: "-49px",
    justifyContent: "center",
    alignItems: "center",
    gap: "31px",
    width: "100%",
  },
});

export const FrameVertical = styled(Box)<{
  top: string;
  left: string;
  dimensions: { width: number; height: number };
}>(({ top, left, dimensions }) => ({
  position: "absolute",
  width: "1px",
  height: `${dimensions.height * 1.4}px`,
  backgroundColor: "#16242C",
  top: top,
  left: left,

  [forMobile]: {
    height: `${dimensions.height * 1.2}px`,
  },
}));

export const FrameHorizontal = styled(Box)<{
  top: string;
  left: string;
  dimensions: { width: number; height: number };
}>(({ top, left, dimensions }) => ({
  position: "absolute",
  width: `${dimensions.width * 1.25}px`,
  height: "1px",
  backgroundColor: "#16242C",
  top: top,
  left: left,

  [forMobile]: {
    width: `${dimensions.width * 1.12}px`,
  },
}));

export const SummitAnalyticsImgContainer = styled(FlexCenteredBothAxises)({
  width: "100%",
  height: "100%",
  position: "relative",
  flexDirection: "column",
  overflow: "hidden",
  padding: "36px 24px",
});

export const SummitAnalyticsWrapper = styled(FlexCenteredHorizontally)({
  marginTop: "30px",
  justifyContent: "space-between",
  width: "100%",
  flexDirection: "row",
  gap: 0,
  alignItems: "center",

  [forMobile]: {
    flexDirection: "column",
    gap: "35px",
    alignItems: "flex-start",
  },
});

export const SummitAnalyticsContainer = styled(FlexCenteredVertically)({
  marginLeft: "37px",
  marginRight: "37px",
  marginTop: "216px",
  marginBottom: "220px",
  flexDirection: "column",
  maxWidth: "812px",

  [forMobile]: {
    marginTop: "40px",
    marginBottom: "83px",
    marginLeft: "27px",
    marginRight: "27px",
  },
});

export const SummitAnalyticMobileDataContainer = styled(Box)({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  rowGap: "20px",
  columnGap: "2px",
  marginTop: "33px",
});

export const SummitAnalyticMobileImgsContainer = styled(Stack)({
  width: "100%",
  flexDirection: "column",
  alignItems: "center",

  "& > :nth-of-type(1)": {
    alignSelf: "center",
  },
  "& > :nth-of-type(2)": {
    alignSelf: "flex-end",
  },
  "& > :nth-of-type(3)": {
    alignSelf: "flex-start",
  },
});

import React from "react";
import { Box, Typography } from "@mui/material";
import {
  FlexCenteredBothAxises,
  FlexCenteredVertically,
} from "../../../styles/styled-component";
import { ButtonStyled, LineWrap } from "../../home/styled";
import logo from "../../../assets/svg/logo.svg";
import { Line } from "../../../assets/tsx/line";

import { useIsMobile } from "../../../utils/use-is-mobile";
import useCountdown from "../../../hooks/use-count-down";

export const TokenizedCapitalSummit = () => {
  const isMobile = useIsMobile();
  const { days, hours, minutes, months } = useCountdown(
    new Date("2025-02-25T00:00:00Z")
  );
  const timerItems = [
    { label: "Months", value: months },
    { label: "Days", value: days },
    { label: "Hours", value: hours },
    { label: "Minutes", value: minutes },
  ];

  return (
    <FlexCenteredBothAxises
      sx={{ flexDirection: "column", position: "relative" }}
    >
      <LineWrap sx={{ left: "12%", top: "15%" }} delay={0.4}>
        <Line height={161} />
      </LineWrap>

      <LineWrap sx={{ left: "20%", top: "73%" }}>
        <Line height={161} />
      </LineWrap>

      <LineWrap sx={{ right: "13%", top: "25%" }} delay={0.2}>
        <Line height={100} />
      </LineWrap>

      <LineWrap sx={{ right: "20%", top: "65%" }} delay={0.6}>
        <Line height={161} />
      </LineWrap>
      <Typography
        fontSize={isMobile ? 50 : 92}
        align="center"
        letterSpacing="-2%"
        lineHeight={isMobile ? "47px" : "87px"}
        fontWeight={500}
        marginTop={isMobile ? "25px" : "52px"}
      >
        Tokenized Capital <br /> Summit{" "}
        <Box component="span" color="#43D090">
          2025
        </Box>
      </Typography>

      <FlexCenteredVertically gap="12px" marginTop="25px">
        <Typography fontSize={isMobile ? 18 : 24}>
          February 25, Denver by
        </Typography>
        <img src={logo} alt="Gamma Prime Logo" height={isMobile ? 31 : 41} />
      </FlexCenteredVertically>

      <FlexCenteredVertically
        marginTop={isMobile ? "24px" : "74px"}
        gap="12px"
        marginBottom="10px"
      >
        {timerItems.map((item, index) => (
          <React.Fragment key={index}>
            <Box
              border="0.5px solid #FFFFFF"
              borderRadius="10px"
              p={isMobile ? "3px 8px" : "8px"}
            >
              <Typography
                variant="h4"
                width={isMobile ? "30px" : "45px"}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {item.value}
              </Typography>
            </Box>
            {index < timerItems.length - 1 && (
              <Typography variant="h4">:</Typography>
            )}
          </React.Fragment>
        ))}
      </FlexCenteredVertically>

      <FlexCenteredVertically gap={isMobile ? "6px" : "5px"}>
        {timerItems.map((item, index) => (
          <Typography
            key={index}
            width="87px"
            variant={isMobile ? "caption" : "h6"}
            align="center"
            fontWeight={300}
          >
            {item.label}
          </Typography>
        ))}
      </FlexCenteredVertically>

      <FlexCenteredBothAxises
        sx={{
          flexDirection: "column",
          marginTop: isMobile ? "24px" : "69px",
          gap: "20px",
          width: isMobile ? 340 : 464,
        }}
      >
        <ButtonStyled
          variant="contained"
          width="100%"
          href="https://lu.ma/z8ph802m"
        >
          Buy Tickets
        </ButtonStyled>
        <FlexCenteredVertically gap="18px" width="100%">
          <ButtonStyled
            variant="outlined"
            width="50%"
            href="https://forms.gle/2FyUmB3oVDygefju7"
          >
            Apply to Speak
          </ButtonStyled>
          <ButtonStyled
            variant="outlined"
            width="50%"
            href="https://forms.gle/Y1vKA3wWYr7EqSZG6"
          >
            Apply to Sponsor
          </ButtonStyled>
        </FlexCenteredVertically>
      </FlexCenteredBothAxises>
    </FlexCenteredBothAxises>
  );
};

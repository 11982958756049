import SummitAnalyticsFirstImg from "../../assets/png/summit-analytics-1.png";
import SummitAnalyticsSecondImg from "../../assets/png/summit-analytics-2.png";
import SummitAnalyticsThirdImg from "../../assets/png/summit-analytics-3.png";
import Agoric from "../../assets/svg/summit-partners/agoric.svg";
import Avascan from "../../assets/svg/summit-partners/avascan.svg";
import B2Network from "../../assets/svg/summit-partners/b2-network.svg";
import BlockFills from "../../assets/svg/summit-partners/block-fills.svg";
import Brinc from "../../assets/svg/summit-partners/brinc.svg";
import Chainge from "../../assets/svg/summit-partners/chainge.svg";
import CoinDcx from "../../assets/svg/summit-partners/coin-dcx.svg";
import ColeFrieman from "../../assets/svg/summit-partners/cole-frieman.svg";
import Ctrl from "../../assets/svg/summit-partners/ctrl.svg";
import Gauntlet from "../../assets/svg/summit-partners/gauntlet.svg";
import GlassNode from "../../assets/svg/summit-partners/glass-node.svg";
import HiddenRoad from "../../assets/svg/summit-partners/hidden-road.svg";
import Indigo from "../../assets/svg/summit-partners/indigo.svg";
import InjectiveProtocol from "../../assets/svg/summit-partners/injective-protocol.svg";
import LayerZero from "../../assets/svg/summit-partners/layer-zero.svg";
import LegionNetwork from "../../assets/svg/summit-partners/legion-network.svg";
import Lido from "../../assets/svg/summit-partners/lido.svg";
import MagicSquare from "../../assets/svg/summit-partners/magic-square.svg";
import Mask from "../../assets/svg/summit-partners/mask.svg";
import NetmindPower from "../../assets/svg/summit-partners/netmind-power.svg";
import Parsiq from "../../assets/svg/summit-partners/parsiq.svg";
import Quasar from "../../assets/svg/summit-partners/quasar.svg";
import Seedify from "../../assets/svg/summit-partners/seedify.svg";
import Stover from "../../assets/svg/summit-partners/stover.svg";
import Talos from "../../assets/svg/summit-partners/talos.svg";
import Tether from "../../assets/svg/summit-partners/tether.svg";
import VybeNetwork from "../../assets/svg/summit-partners/vybe-network.svg";
import Worhmole from "../../assets/svg/summit-partners/worhmole.svg";
import XdefiWallet from "../../assets/svg/summit-partners/xdefi-wallet.svg";
import XYFinance from "../../assets/svg/summit-partners/xy-finance.svg";
import Ani from "../../assets/png/summit-speakers/ani.png";
import Abby from "../../assets/png/summit-speakers/abby.png";
import Abdul from "../../assets/png/summit-speakers/abdul.png";
import AdamWozney from "../../assets/png/summit-speakers/adam-wozney.png";
import AlanEstrada from "../../assets/png/summit-speakers/alan-estrada.png";
import AlexShevchenko from "../../assets/png/summit-speakers/alex-shevchenko.png";
import AnnaShakola from "../../assets/png/summit-speakers/anna-shakola.png";
import DanielOon from "../../assets/png/summit-speakers/daniel-oon.png";
import DrakeBreeding from "../../assets/png/summit-speakers/drake-breeding.png";
import EricVreeland from "../../assets/png/summit-speakers/eric-vreeland.png";
import Jenna from "../../assets/png/summit-speakers/jenna.png";
import Katya from "../../assets/png/summit-speakers/katya.png";
import Kinsa from "../../assets/png/summit-speakers/kinsa.png";
import MarkRichardson from "../../assets/png/summit-speakers/mark-richardson.png";
import MarkSmargon from "../../assets/png/summit-speakers/mark-smargon.png";
import Mark from "../../assets/png/summit-speakers/mark.png";
import MattSchmenk from "../../assets/png/summit-speakers/matt-schmenk.png";
import MichaelKong from "../../assets/png/summit-speakers/michael-kong.png";
import SandiFatic from "../../assets/png/summit-speakers/sandi-fatic.png";
import SeungHyunLee from "../../assets/png/summit-speakers/seung-hyun-lee.png";
import ShiranZhang from "../../assets/png/summit-speakers/shiran-zhang.png";
import TobiasBauer from "../../assets/png/summit-speakers/tobias-bauer.png";
import VictorjJi from "../../assets/png/summit-speakers/victor-ji.png";
import Weso from "../../assets/png/summit-speakers/weso.png";
import JohnDivine from "../../assets/png/summit-speakers/john-divine.png";
import FrancescoAndreoli from "../../assets/png/summit-speakers/francesco-andreoli.png";
import Ray from "../../assets/png/summit-speakers/ray.png";
import RobSarrow from "../../assets/png/summit-speakers/rob-sarrow.png";
import SpeakerTba from "../../assets/png/summit-speakers/speaker-tba.png";
import SarahSchwartz from "../../assets/png/summit-speakers/sarah-schwartz.png";
import ConnorMilner from "../../assets/png/summit-speakers/connor-milner.png";
import TomDunleavy from "../../assets/png/summit-speakers/tom-dunleavy.png";
import Speaker from "../../assets/png/summit-speakers/speaker.png";
import ARCHESpeaker from "../../assets/png/summit-speakers/ARCHE-speaker.png";
import DragonflyCapital from "../../assets/png/summit-speakers-company/dragonfly-capital.png";
import Aethir from "../../assets/png/summit-speakers-company/aethir.png";
import AkashNetwork from "../../assets/png/summit-speakers-company/akash-network.png";
import Aurora from "../../assets/png/summit-speakers-company/aurora.png";
import Avalanche from "../../assets/png/summit-speakers-company/avalanche.png";
import Bancor from "../../assets/png/summit-speakers-company/bancor.png";
import BeefyFinance from "../../assets/png/summit-speakers-company/beefy-finance.png";
import CalimeroNetwork from "../../assets/png/summit-speakers-company/calimero-network.png";
import CoinEasy from "../../assets/png/summit-speakers-company/coin-easy.png";
import Cointelegraph from "../../assets/png/summit-speakers-company/cointelegraph.png";
import CygnusFinance from "../../assets/png/summit-speakers-company/cygnus-finance.png";
import FigmentFund from "../../assets/png/summit-speakers-company/figment-fund.png";
import FuseNetwork from "../../assets/png/summit-speakers-company/fuse-network.png";
import Injective from "../../assets/png/summit-speakers-company/injective.png";
import Linea from "../../assets/png/summit-speakers-company/linea.png";
import MantaNetwork from "../../assets/png/summit-speakers-company/manta-network.png";
import MetaPool from "../../assets/png/summit-speakers-company/meta-pool.png";
import Monad from "../../assets/png/summit-speakers-company/monad.png";
import PolygonLabs from "../../assets/png/summit-speakers-company/polygon-labs.png";
import Polyhedra from "../../assets/png/summit-speakers-company/polyhedra.png";
import SonicLabs from "../../assets/png/summit-speakers-company/sonic-labs.png";
import Sonic from "../../assets/png/summit-speakers-company/sonic.png";
import TbVentures from "../../assets/png/summit-speakers-company/tb-ventures.png";
import Ventures from "../../assets/png/summit-speakers-company/ventures.png";
import Wormhole from "../../assets/png/summit-speakers-company/wormhole.png";
import ARCHE from "../../assets/png/summit-speakers-company/ARCHE.png";
import BlockFillsLogo from "../../assets/png/summit-speakers-company/block-fills.png";
import Consensys from "../../assets/png/summit-speakers-company/consensys.png";
import AsymmetryCapital from "../../assets/png/summit-speakers-company/asymmetry-capital.png";
import VoltCapital from "../../assets/png/summit-speakers-company/volt-capital.png";
import UphonestCapital from "../../assets/png/summit-speakers-company/uphonest-capital.png";
import ZkSync from "../../assets/png/summit-speakers-company/zkSync.png";
import Re7Capital from "../../assets/png/summit-speakers-company/re7-capital.png";
import MVGlobal from "../../assets/png/summit-speakers-company/mv-global.png";
import KhalidMazouz from "../../assets/png/summit-speakers/khalid-mazouz.png";
import M55Capital from "../../assets/png/summit-speakers-company/m55-capital.png";
import HarrisonFrye from "../../assets/png/summit-speakers/harrison-frye.png";
import Acquire from "../../assets/png/summit-speakers-company/acquire.png";

export type SummitAnalyticsDataItem =
  | {
      type: "text";
      amount: string;
      text: string;
      area: string;
      top: string;
      left: string;
    }
  | { type: "img"; url: string; alt: string; area: string };

export const summitAnalyticsData: SummitAnalyticsDataItem[] = [
  {
    type: "text",
    amount: "60",
    text: "Speakers",
    area: "speakers",
    top: "70%",
    left: "-65%",
  },
  {
    type: "img",
    url: SummitAnalyticsFirstImg,
    alt: "Summit Analytics First Img",
    area: "img1",
  },
  {
    type: "text",
    amount: "120",
    text: "Side Events",
    area: "sideEvents",
    top: "35%",
    left: "50%",
  },
  {
    type: "text",
    amount: "2,500",
    text: "Attendees",
    area: "attendees",
    top: "-70%",
    left: "70%",
  },

  {
    type: "img",
    url: SummitAnalyticsSecondImg,
    alt: "Summit Analytics Second Img",
    area: "img2",
  },
  {
    type: "text",
    amount: "35",
    text: "Countries",
    area: "countries",
    top: "40%",
    left: "65%",
  },
  {
    type: "img",
    url: SummitAnalyticsThirdImg,
    alt: "Summit Analytics Third Img",
    area: "img3",
  },
  {
    type: "text",
    amount: "65%",
    text: "C-Level",
    area: "cLevel",
    top: "-30%",
    left: "-80%",
  },
  {
    type: "text",
    amount: "350",
    text: "Companies",
    area: "companies",
    top: "75%",
    left: "-40%",
  },
];

export const summitAnalyticsDataText = [
  { amount: "60", text: "Speakers" },
  { amount: "120", text: "Side Events" },
  { amount: "2,500", text: "Attendees" },
  { amount: "35", text: "Countries" },
  { amount: "65%", text: "C-Level" },
  { amount: "350", text: "Companies" },
];

export const summitAnalyticsDataImg = [
  SummitAnalyticsFirstImg,
  SummitAnalyticsSecondImg,
  SummitAnalyticsThirdImg,
];

export const summitAnalyticsText = `Join Gamma Prime for the Tokenized Capital Summit 2025, the leading institutional event on Real-World Assets (RWA) and Tokenization. Held on February 25th in Colorado, the summit will bring together thousands of participants, including top founders, builders, and institutional leaders shaping the future of finance.
<br/><br/>
Expect stellar speakers, high-impact discussions on tokenizing assets, and unparalleled networking opportunities with fund managers, family offices, and innovators. Discover real-world use cases and strategies driving the next generation of global finance.`;

type summitPartnersLogosProps = {
  alt: string;
  url: string;
};
export const summitPartnersLogos: summitPartnersLogosProps[] = [
  { alt: "Stover Logo", url: Stover },
  { alt: "Talos Logo", url: Talos },
  { alt: "HiddenRoad Logo", url: HiddenRoad },
  { alt: "Cole-Frieman Logo", url: ColeFrieman },
  { alt: "Block Fills Logo", url: BlockFills },
  { alt: "Tether Logo", url: Tether },
  { alt: "LayerZero Logo", url: LayerZero },
  { alt: "Agoric Logo", url: Agoric },
  { alt: "Seedify Logo", url: Seedify },
  { alt: "Mask Logo", url: Mask },
  { alt: "Worhmole Logo", url: Worhmole },
  { alt: "Parsiq Logo", url: Parsiq },
  { alt: "Injectiv Protocol Logo", url: InjectiveProtocol },
  { alt: "Xdefi Wallet Logo", url: XdefiWallet },
  { alt: "Quasar Logo", url: Quasar },
  { alt: "Lido Logo", url: Lido },
  { alt: "Vybe Network Logo", url: VybeNetwork },
  { alt: "Gauntlet Logo", url: Gauntlet },
  { alt: "Ctrl Logo", url: Ctrl },
  { alt: "XYFinance Logo", url: XYFinance },
  { alt: "GlassNode Logo", url: GlassNode },
  { alt: "Chainge Logo", url: Chainge },
  { alt: "CoinDcx Logo", url: CoinDcx },
  { alt: "B2Network Logo", url: B2Network },
  { alt: "Magic Square Logo", url: MagicSquare },
  { alt: "NetmindPower Logo", url: NetmindPower },
  { alt: "Brinc Logo", url: Brinc },
  { alt: "Avascan Logo", url: Avascan },
  { alt: "Legion Network Logo", url: LegionNetwork },
  { alt: "Indigo Logo", url: Indigo },
];

type SummitSpeaker = {
  imgUrl: string;
  speaker: string;
  description: string;
  companyName: string;
  companyImgUrl: string;
};

export const summitSpeakers: SummitSpeaker[] = [
  {
    imgUrl: Ani,
    speaker: "Ani",
    description: "Partner",
    companyName: "Dragonfly Capital",
    companyImgUrl: DragonflyCapital,
  },
  {
    imgUrl: DrakeBreeding,
    speaker: "Drake Breeding",
    description: "Protocol Opportunities Lead",
    companyName: "Figment Fund",
    companyImgUrl: FigmentFund,
  },
  {
    imgUrl: JohnDivine,
    speaker: "John Divine",
    description: "Financial Markets Professional",
    companyName: "BlockFills",
    companyImgUrl: BlockFillsLogo,
  },
  {
    imgUrl: Jenna,
    speaker: "Jenna",
    description: "CEO",
    companyName: "Injective",
    companyImgUrl: Injective,
  },
  {
    imgUrl: Katya,
    speaker: "Katya",
    description: "Head of Growth",
    companyName: "Wormhole",
    companyImgUrl: Wormhole,
  },
  {
    imgUrl: FrancescoAndreoli,
    speaker: "Francesco Andreoli",
    description: "Head of Dev Relations",
    companyName: "Consensys",
    companyImgUrl: Consensys,
  },
  {
    imgUrl: Ray,
    speaker: "Ray",
    description: "Partner",
    companyName: "Asymmetry Capital",
    companyImgUrl: AsymmetryCapital,
  },
  {
    imgUrl: EricVreeland,
    speaker: "Eric Vreeland",
    description: "Chief Strategy Officer",
    companyName: "Polyhedra",
    companyImgUrl: Polyhedra,
  },
  {
    imgUrl: RobSarrow,
    speaker: "Rob Sarrow",
    description: "Investor",
    companyName: "Volt Capital",
    companyImgUrl: VoltCapital,
  },
  {
    imgUrl: SpeakerTba,
    speaker: "Speaker tba",
    description: "-",
    companyName: "Uphonest Capital",
    companyImgUrl: UphonestCapital,
  },
  {
    imgUrl: SarahSchwartz,
    speaker: "Sarah Schwartz",
    description: "Developer Relations Engineer",
    companyName: "zkSync",
    companyImgUrl: ZkSync,
  },
  {
    imgUrl: ConnorMilner,
    speaker: "Connor Milner",
    description: "Business Developer",
    companyName: "Re7 Capital",
    companyImgUrl: Re7Capital,
  },
  {
    imgUrl: Abdul,
    speaker: "Abdul",
    description: "Head of DeFi",
    companyName: "Monad",
    companyImgUrl: Monad,
  },
  {
    imgUrl: TomDunleavy,
    speaker: "Tom Dunleavy",
    description: "Partner",
    companyName: "MV Global",
    companyImgUrl: MVGlobal,
  },
  {
    imgUrl: KhalidMazouz,
    speaker: "Khalid Mazouz",
    description: "General Partner",
    companyName: "M55 Capital",
    companyImgUrl: M55Capital,
  },
  {
    imgUrl: HarrisonFrye,
    speaker: "Harrison Frye",
    description: "co-Founder",
    companyName: "Acquire.Fi",
    companyImgUrl: Acquire,
  },
  {
    imgUrl: AdamWozney,
    speaker: "Adam Wozney",
    description: "Head of Community",
    companyName: "Akash Network",
    companyImgUrl: AkashNetwork,
  },
  {
    imgUrl: MarkSmargon,
    speaker: "Mark Smargon",
    description: "CEO",
    companyName: "Fuse Network",
    companyImgUrl: FuseNetwork,
  },
  {
    imgUrl: MattSchmenk,
    speaker: "Matt Schmenk",
    description: "Ecosystem Growth",
    companyName: "Avalanche",
    companyImgUrl: Avalanche,
  },
  {
    imgUrl: DanielOon,
    speaker: "Daniel Oon",
    description: "Head of DeFi",
    companyName: "Polygon Labs",
    companyImgUrl: PolygonLabs,
  },
  {
    imgUrl: MichaelKong,
    speaker: "Michael Kong",
    description: "Founder",
    companyName: "Sonic Labs (former Fantom)",
    companyImgUrl: SonicLabs,
  },
  {
    imgUrl: VictorjJi,
    speaker: "Victor Ji",
    description: "co-Founder",
    companyName: "Manta Network",
    companyImgUrl: MantaNetwork,
  },
  {
    imgUrl: Speaker,
    speaker: "Speaker tba",
    description: "-",
    companyName: "Linea",
    companyImgUrl: Linea,
  },

  {
    imgUrl: Abby,
    speaker: "Abby",
    description: "Partner",
    companyName: "ODIG Ventures",
    companyImgUrl: Ventures,
  },
  {
    imgUrl: ARCHESpeaker,
    speaker: "Speaker tba",
    description: "-",
    companyName: "ARCHE capital",
    companyImgUrl: ARCHE,
  },
  {
    imgUrl: AnnaShakola,
    speaker: "Anna Shakola",
    description: "Head of BD",
    companyName: "Cointelegraph",
    companyImgUrl: Cointelegraph,
  },
  {
    imgUrl: TobiasBauer,
    speaker: "Tobias Bauer",
    description: "Partner",
    companyName: "TB Ventures",
    companyImgUrl: TbVentures,
  },
  {
    imgUrl: AlexShevchenko,
    speaker: "Alex Shevchenko",
    description: "CEO",
    companyName: "Aurora",
    companyImgUrl: Aurora,
  },
  {
    imgUrl: Mark,
    speaker: "Mark",
    description: "co-Founder",
    companyName: "Aethir",
    companyImgUrl: Aethir,
  },
  {
    imgUrl: SandiFatic,
    speaker: "Sandi Fatic",
    description: "CEO",
    companyName: "Calimero Network",
    companyImgUrl: CalimeroNetwork,
  },
  {
    imgUrl: ShiranZhang,
    speaker: "Shiran Zhang",
    description: "Ecosystem Lead",
    companyName: "Cygnus Finance",
    companyImgUrl: CygnusFinance,
  },
  {
    imgUrl: AlanEstrada,
    speaker: "Alan Estrada",
    description: "Developer Evangelist",
    companyName: "Meta Pool",
    companyImgUrl: MetaPool,
  },
  {
    imgUrl: Weso,
    speaker: "Weso",
    description: "Captain Beefy",
    companyName: "Beefy Finance",
    companyImgUrl: BeefyFinance,
  },
  {
    imgUrl: MarkRichardson,
    speaker: "Mark B. Richardson",
    description: "Product Lead",
    companyName: "Bancor",
    companyImgUrl: Bancor,
  },
  {
    imgUrl: SeungHyunLee,
    speaker: "Seung Hyun Lee",
    description: "Founder",
    companyName: "CoinEasy",
    companyImgUrl: CoinEasy,
  },
  {
    imgUrl: Kinsa,
    speaker: "Kinsa",
    description: "CMO",
    companyName: "Sonic SVM",
    companyImgUrl: Sonic,
  },
];

import { Box, Typography } from "@mui/material";
import { Badge } from "../../../components/badge";
import { FlexCenteredBothAxises } from "../../../styles/styled-component";
import { HideComponent, SummitScheduleWrapper } from "../styled";
import { useIsMobile } from "../../../utils/use-is-mobile";

export const SummitSchedule = () => {
  const isMobile = useIsMobile();
  return (
    <FlexCenteredBothAxises
      style={{
        flexDirection: "column",
        position: "relative",
        marginLeft: "19px",
        marginRight: "19px",
      }}
    >
      <HideComponent />

      <Badge
        text="Coming soon: Agenda"
        type="gradient"
        size="small"
        className="zIndex"
      />
      <Typography
        fontSize={isMobile ? 32 : 48}
        fontWeight={500}
        marginTop={isMobile ? "9px" : "19px"}
        align="center"
        zIndex={5}
      >
        Summit Schedule
      </Typography>
      <Typography
        maxWidth="413px"
        align="center"
        sx={{ opacity: 0.6 }}
        marginTop="17px"
        marginBottom="57px"
        zIndex={5}
      >
        Summit's Schedule is carefully curated to provide an engaging and
        insightful experience for all attendees
      </Typography>
      <SummitScheduleWrapper>
        <Typography fontSize={isMobile ? 12 : 24} fontWeight={700}>
          9:00am:{" "}
          <Box component="span" fontWeight={400}>
            Registration Opens
          </Box>
        </Typography>
      </SummitScheduleWrapper>
      <Box width="1px" height="48px" bgcolor="white" my="10px"></Box>
      <SummitScheduleWrapper>
        <Typography fontSize={isMobile ? 12 : 24} fontWeight={700}>
          9:30am:{" "}
          <Box component="span" fontWeight={400}>
            Coffee & Networking
          </Box>
        </Typography>
      </SummitScheduleWrapper>
    </FlexCenteredBothAxises>
  );
};

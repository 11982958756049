import { Typography, styled } from "@mui/material";
import { FC, memo } from "react";
import { forMobile } from "../styles/adaptivity";

interface IProps {
  text?: string;
  type: "primary" | "secondary" | "gradient";
  size: "small" | "medium";
  className?: string;
}

const BadgeStyle = styled("div")<IProps>(({ size, type }) => ({
  borderRadius: "200px",
  maxWidth: "fit-content",
  border:
    type === "primary"
      ? "1px solid #39CA88"
      : type === "gradient"
      ? "1px solid #FFFFFF0F"
      : "1px solid rgba(189, 137, 255, 0.20)",
  background:
    type === "primary"
      ? "rgba(57, 202, 136, 0.20)"
      : type === "gradient"
      ? `
        linear-gradient(107.26deg, #081116 4.87%, #081116 75.88%),
        linear-gradient(90deg, rgba(57, 202, 136, 0.3) 0.01%, rgba(57, 202, 185, 0.09) 50.01%, rgba(54, 238, 216, 0.13) 100%)
      `
      : "rgba(189, 137, 255, 0.10)",
  padding: size === "medium" ? 10 : "6px 10px",
  ...(type === "gradient" && {
    boxShadow: `
      0px -7px 11px 0px #A48FFF1F inset
      `,
    backdropFilter: "blur(6px)",
  }),
  [forMobile]: {
    padding: size === "medium" ? 10 : "2px 7px",
  },
}));

export const Badge: FC<IProps> = memo(({ text, type, size, className }) => {
  return (
    <BadgeStyle type={type} size={size} className={className}>
      <Typography
        fontSize={size === "medium" ? "12px" : "14px"}
        fontWeight={size === "medium" ? 700 : 400}
        lineHeight={size === "medium" ? "124%" : "normal"}
        letterSpacing={size === "medium" ? "-0.36px" : "-0.7px"}
        color={
          type === "primary"
            ? "#39CA88"
            : type === "secondary"
            ? "#D384F8"
            : undefined
        }
        whiteSpace="nowrap"
        style={
          type === "gradient"
            ? {
                background: `
                  linear-gradient(90deg, #39CA88 0.01%, #63A1FF 50.01%, #3995CA 100%),
                  linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))
                `,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }
            : {}
        }
      >
        {text}
      </Typography>
    </BadgeStyle>
  );
});

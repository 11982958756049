export const PagesPath = {
  home: "/",
  careers: "/сareers",
  aboutUs: "/about-us",
  privacyPoilicy: "/privacy-poilicy",
  getInTouch: "/get-in-touch",
  termsofService: "/terms-of-service",
  blog: "/blog",
  blogArticle: "/blog/:id",
  summit: "/summit",
} as const;

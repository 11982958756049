import { DefaultWrapper } from "../../pages/default-wrapper";
import { TokenizedCapitalSummit } from "./components/tokenized-capital-summit";
import { SummitAnalytics } from "./components/summit-analytics";
import { SummitPartners } from "./components/summit-partners";
import { SummitLocation } from "./components/summit-location";
import { SummitSpeakers } from "./components/summit-speakers";
import { SummitSchedule } from "./components/summit-schedule";
import { Form } from "../../components/form";
import { FAQ } from "../../components/faq";

const Summit = () => {
	return (
		<DefaultWrapper>
			<>
				<TokenizedCapitalSummit />
				<SummitAnalytics />
				<SummitPartners />
				<SummitLocation />
				<SummitSpeakers />
				<SummitSchedule />
				<FAQ />
				<Form
					withMessage
					title={"Secure Your Position at the Forefront of Finance"}
					badge="Join Beta"
					badgeType="gradient"
				/>
			</>
		</DefaultWrapper>
	);
};

export default Summit;

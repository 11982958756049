import { useState, useEffect } from "react";
import moment from "moment";

const formatNumber = (num: number) => (num < 10 ? `0${num}` : num);

const useCountdown = (targetDate: Date) => {
	const calculateTimeLeft = () => {
		const now = moment();
		const target = moment(targetDate);
		const duration = moment.duration(target.diff(now));

		return {
			months: formatNumber(Math.floor(duration.asMonths())), // Full months remaining
			days: formatNumber(duration.days()), // Remaining days in the current month
			hours: formatNumber(duration.hours()),
			minutes: formatNumber(duration.minutes()),
		};
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer); // Cleanup interval on component unmount
	}, [targetDate]);

	return timeLeft;
};

export default useCountdown;

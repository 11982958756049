import { Typography } from "@mui/material";
import { Badge } from "../../../components/badge";
import {
  FlexCenteredBetween,
  FlexCenteredBothAxises,
  FlexCenteredVertically,
} from "../../../styles/styled-component";
import { summitSpeakers } from "../common";
import { LeftLight, RightLight } from "../styled";
import { useIsMobile } from "../../../utils/use-is-mobile";

export const SummitSpeakers = () => {
  const isMobile = useIsMobile();
  return (
    <FlexCenteredBothAxises
      style={{
        flexDirection: "column",
        marginBottom: isMobile ? "102px" : "291px",
        position: "relative",
        marginLeft: "19px",
        marginRight: "19px",
      }}
    >
      {!isMobile && (
        <>
          <LeftLight />
          <RightLight />
        </>
      )}

      <Badge text="Speakers" type="gradient" size="small" />
      <Typography
        fontSize={isMobile ? 32 : 64}
        lineHeight="60px"
        fontWeight={500}
        marginTop="20px"
        color="white"
        align="center"
      >
        Industry Leaders
      </Typography>
      <Typography
        maxWidth="413px"
        align="center"
        sx={{ opacity: 0.6 }}
        marginTop="25px"
        marginBottom="92px"
      >
        Join us at the summit to hear from exceptional speakers who are leaders
        and innovators in their fields, sharing insights that inspire and
        empower
      </Typography>
      <FlexCenteredBetween
        style={{
          columnGap: isMobile ? "0px" : "12px",
          flexWrap: "wrap",
          justifyContent: "space-around",
          maxWidth: "993px",
          rowGap: "42px",
        }}
      >
        {summitSpeakers.map((speaker, index) => (
          <FlexCenteredVertically
            width={isMobile ? "183px" : "323px"}
            key={index}
            style={{ flexDirection: "column" }}
          >
            <img
              src={speaker.imgUrl}
              alt={speaker.speaker}
              width={isMobile ? 93 : 187}
              height={isMobile ? 93 : 187}
              style={{ marginBottom: isMobile ? "" : "-24px" }}
            />
            <Typography
              variant={isMobile ? "caption" : "h6"}
              fontWeight={600}
              marginBottom={isMobile ? "0px" : "6px"}
              letterSpacing="-4%"
            >
              {speaker.speaker}
            </Typography>
            <Typography
              fontWeight={500}
              fontSize={isMobile ? 10 : 16}
              style={{ opacity: "60%" }}
              marginBottom={isMobile ? "0px" : "6px"}
              letterSpacing="-3%"
            >
              {speaker.description}
            </Typography>
            <FlexCenteredVertically gap={isMobile ? "4px" : "12px"}>
              <img
                src={speaker.companyImgUrl}
                alt={speaker.companyName}
                width={isMobile ? 12 : 22}
                height={isMobile ? 12 : 22}
              />
              <Typography
                fontWeight={500}
                color="#39CA88"
                letterSpacing="-3%"
                fontSize={isMobile ? 10 : 16}
              >
                {speaker.companyName}
              </Typography>
            </FlexCenteredVertically>
          </FlexCenteredVertically>
        ))}
      </FlexCenteredBetween>
    </FlexCenteredBothAxises>
  );
};

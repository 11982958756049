import { FC, memo } from "react";
import { FooterContainer, FooterStyled, LinkStyledFooter } from "./styled";
import { FlexCenteredBetween, FlexCenteredVertically } from "../../styles/styled-component";
import { PagesPath } from "../../utils/route";
import logo from "../../assets/svg/logo.svg";
import { Typography } from "@mui/material";
import linkedIn from "../../assets/svg/linkedin.svg";
import youtube from "../../assets/svg/youtube.svg";
import twitter from "../../assets/svg/twitter.svg";
import mail from "../../assets/svg/mail.svg";
import { headerLinks } from "../header";
import { LinkStyled } from "../header/styled";
import { Link, useNavigate } from "react-router-dom";
import { useIsMobile } from "../../utils/use-is-mobile";

export const Footer: FC = memo(() => {
	const isMobile = useIsMobile();
	const navigate = useNavigate();
	return (
		<FooterStyled>
			<FooterContainer>
				<FlexCenteredBetween
					sx={{
						flexDirection: isMobile ? "column" : "row",
						alignItems: isMobile ? "start" : "center",
						gap: isMobile ? "36px" : "0",
					}}
				>
					<Link to={PagesPath.home}>
						<img src={logo} alt="logo" />
					</Link>

					{isMobile ? (
						<FlexCenteredVertically gap="50px">
							{headerLinks.map((l) => (
								<LinkStyled key={l.page} to={l.path}>
									<Typography variant="body1" fontWeight={400}>
										{l.page}
									</Typography>
								</LinkStyled>
							))}
						</FlexCenteredVertically>
					) : (
						<FlexCenteredVertically gap="14px">
							<Link
								to="https://x.com/gammaprime_fi"
								target="_blank"
								style={{
									border: "1px solid #ffffff2b",
									borderRadius: "100%",
									display: "flex",
									justifyContent: "center",
									width: 42,
									height: 42,
								}}
							>
								<img src={twitter} alt="twitter" width={20} />
							</Link>
							<Link
								to="https://www.youtube.com/channel/UCYCQQOOa4hHQvBkJHqYtxfw"
								target="_blank"
								style={{
									border: "1px solid #ffffff2b",
									borderRadius: "100%",
									display: "flex",
									justifyContent: "center",
									width: 42,
									height: 42,
								}}
							>
								<img src={youtube} alt="linkedin" width={20} />
							</Link>
							<Link
								to="https://www.linkedin.com/company/gamma-prime-defi/"
								target="_blank"
							>
								<img src={linkedIn} alt="linkedIn" />
							</Link>
							<LinkStyledFooter to="mailto:info@gammaprime.fi" target="_blank">
								<img src={mail} alt="mail" />
								<Typography variant="body1" fontWeight={400}>
									info@gammaprime.fi
								</Typography>
							</LinkStyledFooter>
						</FlexCenteredVertically>
					)}
				</FlexCenteredBetween>

				<FlexCenteredBetween
					sx={{
						marginTop: isMobile ? "24px" : "51px",
						flexDirection: isMobile ? "column" : "row",
						alignItems: isMobile ? "start" : "center",
						gap: isMobile ? "36px" : "0",
					}}
				>
					{isMobile ? (
						<FlexCenteredVertically gap="14px">
							<Link
								to="https://x.com/gammaprime_fi"
								target="_blank"
								style={{
									border: "1px solid #ffffff2b",
									borderRadius: "100%",
									display: "flex",
									justifyContent: "center",
									width: 42,
									height: 42,
								}}
							>
								<img src={twitter} alt="twitter" width={20} />
							</Link>
							<Link
								to="https://www.youtube.com/channel/UCYCQQOOa4hHQvBkJHqYtxfw"
								target="_blank"
								style={{
									border: "1px solid #ffffff2b",
									borderRadius: "100%",
									display: "flex",
									justifyContent: "center",
									width: 42,
									height: 42,
								}}
							>
								<img src={youtube} alt="linkedin" width={20} />
							</Link>
							<Link
								to="https://www.linkedin.com/company/gamma-prime-defi/"
								target="_blank"
							>
								<img src={linkedIn} alt="linkedIn" />
							</Link>
							<LinkStyledFooter to="mailto:info@gammaprime.fi" target="_blank">
								<img src={mail} alt="mail" />

								<Typography variant="body1" fontWeight={400}>
									info@gammaprime.fi
								</Typography>
							</LinkStyledFooter>
						</FlexCenteredVertically>
					) : (
						<FlexCenteredVertically gap="50px">
							{headerLinks.map((l) => (
								<LinkStyled key={l.page} to={l.path}>
									<Typography variant="body1" fontWeight={400}>
										{l.page}
									</Typography>
								</LinkStyled>
							))}
						</FlexCenteredVertically>
					)}

					<FlexCenteredVertically gap="37px">
						<Typography
							variant="body1"
							fontWeight={400}
							sx={{ opacity: 0.6, cursor: "pointer" }}
							letterSpacing="-0.48px"
							onClick={() => navigate(PagesPath.privacyPoilicy)}
						>
							Privacy Policy
						</Typography>

						<Typography
							variant="body1"
							fontWeight={400}
							sx={{ opacity: 0.6, cursor: "pointer" }}
							letterSpacing="-0.48px"
							onClick={() => navigate(PagesPath.termsofService)}
						>
							Terms of Service
						</Typography>
					</FlexCenteredVertically>
				</FlexCenteredBetween>
			</FooterContainer>
		</FooterStyled>
	);
});

Footer.displayName = "Footer";
